.header {
	max-width: 1350px;
	margin: 0 auto;

	.position {
		padding: 25px;
		border-radius: 20px;

		.wrapper {
			width: 100%;
			max-width: 1120px;
			display: flex;
			justify-content: space-between;
			margin: auto;
			gap: 30px;

			@media screen and (max-width: 820px) {
				flex-wrap: wrap;
				justify-content: center;
				text-align: center;
			}
		}

		.content {
			font-size: 64px;
			font-weight: bold;
			margin: auto auto auto 30px;
			max-width: 690px;
			display: flex;
			align-items: center;
			gap: 26px;
			line-height: 1.2;


			@media screen and (max-width: 820px) {
				flex-wrap: wrap;
				justify-content: center;
				margin: auto !important;
				text-align: center;
			}
		}

		.title-logo {
			max-width: 140px;
			max-height: 140px;
			overflow: hidden;
			border-radius: 12px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		svg {
			width: 350px;
			height: 350px;
		}

		@media screen and (max-width: 1300px) {
			.content {
				font-size: 48px;
				max-width: 500px;
			}

			svg {
				width: 300px;
				height: 300px;
			}
		}

		@media screen and (max-width: 1050px) {
			.content {
				font-size: 36px;
			}

			svg {
				max-width: 230px;
				max-height: 230px;
			}
		}

		@media screen and (max-width: 600px) {
			.wrapper {
				display: block;
				text-align: center;
				margin-bottom: 20px;
			}

			.content {
				margin: 0 0 30px;
			}
		}
	}

	.header-games {
		background: #dfdaff;
	}

	.header-game-details {
		background: #f2d2f4;
		// padding: 0 50px 0 25px;

		.content {
			margin: auto auto auto 60px;
		}

		@media screen and (max-width: 650px) {
			padding: 0 25px 0 0;
		}

		@media screen and (max-width: 600px) {
			padding: 25px;

			.content {
				margin: 0 0 20px 0;
			}
		}
	}

	.header-careers {
		background: #ffc998;
	}

	.header-job-details {
		background: #d6d6fd;
	}

	.header-contacts {
		background: #d5e9ca;
	}

	.header-about {
		background: #d4e8de;
	}

	.header-services {
		background: #d4dde8;
	}
}

.beauty {
	display: initial;
	white-space: nowrap;
	background-image: linear-gradient(90deg, #8c2dc5 0%, #72afe9 100%);
	background-size: 100%;
	background-repeat: repeat;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;

	@media screen and (max-width: 425px) {
		white-space: initial;
	}
}
